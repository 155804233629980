import { useState, useEffect } from 'react';

type SetValue<T> = (value: T) => void;
type UseLocalStorageResult<T> = [T, SetValue<T>];

export const useLocalStorage = <T>(
  key: string,
  initialValue: T,
): UseLocalStorageResult<T> => {
  const [value, setValue] = useState<T>(() => {
    if (typeof window !== `undefined`) {
      const storedValue = window.localStorage.getItem(key);
      if (storedValue !== null) {
        return JSON.parse(storedValue) as T;
      }
    }
    return initialValue;
  });

  useEffect(() => {
    if (typeof window !== `undefined`) {
      window.localStorage.setItem(key, JSON.stringify(value));
    }
  }, [key, value]);

  return [value, setValue];
};
