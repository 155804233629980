import { BaseComponentProps } from '@/types';
import * as React from 'react';

function MediumIcon(props: BaseComponentProps) {
  const { className } = props;
  return (
    <svg
      {...props}
      width={24}
      height={24}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 30 30"
    >
      <path d="M8.5 7a8.5 8.5 0 1 0 0 17 8.5 8.5 0 1 0 0-17zM22 8a4 7.5 0 1 0 0 15 4 7.5 0 1 0 0-15zm6.5 1a1.5 6.5 0 1 0 0 13 1.5 6.5 0 1 0 0-13z" />
    </svg>
  );
}

export default MediumIcon;
