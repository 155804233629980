import mixpanel, { Dict } from 'mixpanel-browser';

const env = true;

export const Mixpanel = new (class {
  protected mixpanel: any;

  constructor(token: string) {
    if (typeof token !== `string` || token.length < 1) {
      throw new Error(`Invalid mixpanel token`);
    }

    mixpanel.init(token, {
      loaded: (_mixpanel) => {
        this.mixpanel = _mixpanel;
      },
    });

    mixpanel.identify();
  }

  identify = (id: string) => {
    if (env) this.mixpanel.identify(id);
  };

  alias = (id: string) => {
    if (!env) this.mixpanel.alias(id);
  };

  track = (name: string, props?: Dict) => {
    if (env) this.mixpanel.track(name, props);
  };

  setPeople = (props: Dict) => {
    if (env) this.mixpanel.people.set(props);
  };

  setOnce = (props: Dict) => {
    if (env) this.mixpanel.people.set_once(props);
  };

  register = (props: Dict, options = { persistent: true }) => {
    if (env) this.mixpanel.register(props, options);
  };
})(process.env.GATSBY_MIXPANEL_TOKEN as string);
