/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';

export default function UnsubscribePopup() {
  const [displayPopup, setDisplayPopup] = useState(false);

  useEffect(() => {
    let displayTimer: ReturnType<typeof setTimeout>;

    if (displayPopup) {
      displayTimer = setTimeout(() => {
        setDisplayPopup(false);
      }, 4000);
    }
    return () => clearTimeout(displayTimer);
  }, [displayPopup]);

  const handleClick = () => {
    setDisplayPopup(true);
  };

  return (
    <div>
      <p
        onClick={handleClick}
        className="text-[16px] font-normal text-gray-light hover:text-[#FF713F] cursor-pointer"
      >
        Unsubscribe
      </p>
      {displayPopup && (
        <div className="fixed top-0 bottom-0 left-0 right-0 z-[10000] flex items-center justify-center w-screen h-screen overflow-auto bg-[rgba(0,_0,_0,_0.7)]">
          <p className="font-medium text-navy-blue m-auto text-lg text-center flex justify-center min-h-[200px] max-w-[600px] flex-col items-center gap-6 bg-white p-[20px] xs:rounded-[24px] rounded">
            To unsubscribe from your membership, please contact us at
            hello@vitract.com.
          </p>
        </div>
      )}
    </div>
  );
}
